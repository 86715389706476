import type { ActionContext } from 'vuex';
import axios, { CancelTokenSource } from 'axios';
import {
	ActionTypes,
	BackgroundExportOptions,
	EFFECT_IMAGE_TYPE,
	EffectExportOptions,
	EXPORT_TYPES,
	ExportAIStyledImageWithTransformation,
	ExportDownloadItem,
	ExportDownloadType,
	ExportImageWithTransformation,
	FBXExportFile,
	IExportState,
	MutationTypes,
	NAMESPACE,
	PixRenderExportStatus,
	PixRenderExportType,
	PixRenderFormat,
	VIDEO_FORMAT,
	ProjectTypes,
	EXPORT_FORMAT,
} from '@pixcap/ui-core/models/store/projectexports.interface';
import { actionsWrapper, mutationsWrapper } from '@pixcap/ui-core/store/projectexports/wrapper';

import { $notify } from '@pixcap/ui-core/helpers/notification';
import { $toast } from '@pixcap/ui-core/helpers/toast';
import { MutationTypes as AppMutationTypes, NAMESPACE as APP_NAMESPACE, NotificationType } from '@pixcap/ui-core/models/store/app.interface';
import { appUtilities } from '@pixcap/ui-core/modules/appUtilities';
import { getEngineBridge } from '@pixcap/ui-core/services/utils';
import { RSocketCancelToken, WEBSOCKET_OPERATIONS, WebSocketManager } from '@pixcap/ui-core/services/socketmanager';
import { v4 as uuidv4 } from 'uuid';
import { PROGRESS_STATUS_MAPPING } from '@pixcap/ui-core/constants/projectexports.constants';
import { base64ToArrayBuffer } from '@pixcap/ui-core/utils/StringUtils';
import { downloadDirectFromPresignedUrl, downloadDirectFromUrl } from '@pixcap/ui-core/utils/WindowUtils';
import logger from '@pixcap/ui-core/helpers/logger';
import { longPoll } from '@pixcap/ui-core/utils/HttpUtils';
import { httpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';
import { API_PATHS, MULTIPART_UPLOAD_TYPES } from '@pixcap/ui-core/constants/api.constants';
import { actionsWrapper as ExportActions } from '@pixcap/ui-core/store/projectexports';
import ServerRenderManager from '@pixcap/ui-core/services/ServerRenderManager';

const { ASSETMANAGER_PATH } = API_PATHS;

const CancelToken = axios.CancelToken;
let cancelPollExportFbxStatus: CancelTokenSource;
let fbxExportsCancelToken: RSocketCancelToken;
let serverExportCancelToken: RSocketCancelToken;

async function getAiGeneratedStatus(id: string): Promise<{ downloadFileName: string; presignedUrl: string }> {
	const response = await httpClient.get(`${ASSETMANAGER_PATH}/export/renderer/aistylist/styles/${id}`);
	const { downloadFileName, presignedUrl } = response.data;
	if (presignedUrl)
		return {
			downloadFileName,
			presignedUrl,
		};
	return undefined;
}

async function getBlenderRenderStatus(
	rId: string
): Promise<{ outputFileId: string; exportType: PixRenderExportType; fileName: string; format: PixRenderFormat; downloaded: boolean }> {
	const response = await httpClient.get(`${ASSETMANAGER_PATH}/export/renderer/status/${rId}`);
	const { isFailed, outputFileId, exportType, fileName, format, downloaded } = response.data;
	if (isFailed) throw new Error('Job failed');
	if (outputFileId)
		return {
			outputFileId,
			exportType,
			fileName,
			format,
			downloaded,
		};
	return undefined;
}

async function getPresignedUrl(fileID: string, attachmentFilename: string): Promise<string> {
	const response = await httpClient.get(`${ASSETMANAGER_PATH}/presigned/${fileID}`, {
		params: {
			attachmentFilename,
		},
	});
	const { presignedUrl } = response.data;
	return presignedUrl;
}

export async function downloadServerRenderFileByRenderId(params: {
	exportType: string;
	format: string;
	fileName: string;
	outputFileId: string;
	renderId: string;
}) {
	const { exportType, format, fileName, outputFileId, renderId } = params;
	const extension = exportType == 'image' ? (format == 'PNG' ? 'png' : 'jpg') : exportType == 'video' ? 'mp4' : 'gif';
	const downloadFileName = `${fileName}.${extension}`;
	const presignedUrl = await getPresignedUrl(outputFileId, downloadFileName);
	downloadDirectFromUrl(presignedUrl, fileName);
	httpClient
		.put(`${ASSETMANAGER_PATH}/export/renderer/${renderId}`, {
			downloaded: true,
		})
		.catch(() => {});
}

export default {
	[ActionTypes.EXPORT_VIDEO](
		{ dispatch },
		payload: {
			projectTitle: string;
			width: number;
			height: number;
			format: VIDEO_FORMAT;
			startFrame: number;
			endFrame: number;
			fps: number;
			overridingWatermarkFlag?: boolean;
			isDownload?: boolean;
		}
	) {
		const { projectTitle, format, width, height, startFrame, endFrame, fps, overridingWatermarkFlag, isDownload = true } = payload;
		const exportFrameIndices = [];
		for (let i = startFrame; i <= endFrame; i++) {
			exportFrameIndices.push(i);
		}
		return dispatch(
			`${NAMESPACE}/${ActionTypes.EXPORT_VIDEO_OR_GIF}`,
			{
				projectTitle,
				exportFrameIndices,
				width,
				height,
				fps,
				format,
				loadingMessage: 'ANIMATION IS BEING RENDERED',
				exportType: 'video',
				startFrame,
				endFrame,
				overridingWatermarkFlag,
				isDownload,
			},
			{ root: true }
		);
	},
	[ActionTypes.EXPORT_GIF](
		{ dispatch },
		payload: {
			projectTitle: string;
			width: number;
			height: number;
			startFrame: number;
			endFrame: number;
			fps: number;
			frameDropIncrement: number;
			transparentBackground?: boolean;
			overridingWatermarkFlag?: boolean;
			isDownload?: boolean;
		}
	) {
		const {
			projectTitle,
			width,
			height,
			startFrame,
			endFrame,
			fps,
			transparentBackground,
			overridingWatermarkFlag,
			frameDropIncrement,
			isDownload = true,
		} = payload;
		const exportFrameIndices: number[] = [];

		let frameSum = 0;
		for (let i = startFrame; i <= endFrame; i++) {
			const upperCeiling = frameSum % 1 == 0 ? frameSum + 1 : Math.ceil(frameSum);
			if (upperCeiling > frameSum + frameDropIncrement) exportFrameIndices.push(i);
			frameSum = frameSum + frameDropIncrement;
		}
		return dispatch(
			`${NAMESPACE}/${ActionTypes.EXPORT_VIDEO_OR_GIF}`,
			{
				projectTitle,
				width,
				height,
				fps,
				format: 'gif',
				loadingMessage: 'ANIMATION IS BEING RENDERED',
				exportType: 'gif',
				repeat: 0,
				startFrame,
				endFrame,
				transparentBackground,
				overridingWatermarkFlag,
				exportFrameIndices,
				isDownload,
			},
			{ root: true }
		);
	},
	[ActionTypes.EXPORT_VIDEO_OR_GIF](
		{ commit },
		payload: {
			projectTitle: string;
			exportFrameIndices: number[]; // only export frames specified in array
			width: number;
			height: number;
			fps: number;
			repeat?: number;
			format: string;
			loadingMessage: string;
			exportType: 'video' | 'gif';
			startFrame: number;
			endFrame: number;
			transparentBackground?: boolean;
			overridingWatermarkFlag?: boolean;
			isDownload: boolean;
		}
	) {
		getEngineBridge().pauseAnimation();
		return new Promise<Blob | void>(async (exportResolve, exportReject) => {
			const {
				projectTitle,
				format,
				loadingMessage,
				exportType,
				fps,
				repeat,
				startFrame,
				endFrame,
				transparentBackground = false,
				overridingWatermarkFlag,
				exportFrameIndices,
				isDownload,
			} = payload;
			let width = payload.width;
			let height = payload.height;
			// width and height must be divisible by 2
			width = width % 2 ? width + 1 : width;
			height = height % 2 ? height + 1 : height;
			commit(`${NAMESPACE}/${MutationTypes.UPDATE_EXPORTING_VIDEO_STATUS}`, { progress: 0, message: loadingMessage }, { root: true });
			commit(`${NAMESPACE}/${MutationTypes.SET_IS_EXPORTING_VIDEO}`, true, { root: true });
			const frames = endFrame - startFrame;
			let currentFrame = startFrame;

			const updateVideoStatus = async (isSendingFrames = true) => {
				if (isSendingFrames) {
					const progress = Math.floor(((currentFrame - startFrame) * 99) / frames);
					commit(`${NAMESPACE}/${MutationTypes.UPDATE_EXPORTING_VIDEO_STATUS}`, { progress, message: loadingMessage }, { root: true });
				} else {
					appUtilities.$widgets.shortcutManager.disableShortcuts = false;
					getEngineBridge().setCurrentFrame(startFrame);
					commit(`${NAMESPACE}/${MutationTypes.UPDATE_EXPORTING_VIDEO_STATUS}`, null, { root: true });
					$toast.open({
						message: `Processing...`,
						type: NotificationType.INFO,
						duration: 3000,
					});
				}
			};

			try {
				const start = performance.now();
				appUtilities.$widgets.shortcutManager.disableShortcuts = true;

				const jobId = uuidv4();
				const jobData = {
					jobId,
					fileName: projectTitle,
					exportType,
					fps,
					height,
					width,
					repeat,
				};
				await httpClient.post(`${ASSETMANAGER_PATH}/export/gifVideo/init`, jobData);

				const renderFrame = async () => {
					return new Promise<any>((resolve, reject) => {
						setTimeout(async () => {
							try {
								const isSkipPreStage = startFrame != currentFrame;
								const isSkipPostStage = exportFrameIndices[exportFrameIndices.length - 1] != currentFrame;
								const frameBase64 = await getEngineBridge().exportImage(
									width,
									height,
									exportType == 'gif',
									transparentBackground,
									isSkipPreStage,
									isSkipPostStage,
									overridingWatermarkFlag
								);
								resolve({ frameBase64 });
							} catch (error) {
								reject(error);
							}
						}, 0);
					});
				};

				const imageUploadPromises = [];

				const uploadFrameImage = async (frameBase64: any) => {
					const frameIndex = exportFrameIndices.indexOf(currentFrame);

					const base64Response = await fetch(frameBase64);
					const frameBlob = await base64Response.blob();

					const imageContentType = transparentBackground ? `image/png` : `image/jpg`;
					const extension = transparentBackground ? `png` : `jpg`;

					await appUtilities.$services.multiUploadService.multipartUpload(
						frameBlob,
						exportType == 'gif' ? MULTIPART_UPLOAD_TYPES.GIF_EXPORTS : MULTIPART_UPLOAD_TYPES.VIDEO_EXPORTS,
						{ key: `${jobId}/${frameIndex}.${extension}`, contentType: imageContentType }
					);
				};
				getEngineBridge().setCurrentFrame(currentFrame);
				while (currentFrame <= endFrame) {
					if (exportFrameIndices.includes(currentFrame)) {
						const { frameBase64 } = await renderFrame();
						imageUploadPromises.push(uploadFrameImage(frameBase64));
					}
					updateVideoStatus();
					currentFrame += 1;
					getEngineBridge().setCurrentFrame(currentFrame);
				}
				await Promise.all(imageUploadPromises);
				updateVideoStatus(false);

				await httpClient.put(`${ASSETMANAGER_PATH}/export/gifVideo/execute/${jobId}`);

				const pollRequest = async () => {
					return await httpClient.get(`${ASSETMANAGER_PATH}/export/gifVideo/status/${jobId}`);
				};

				const mediaStitchExportResponseDto = await longPoll<{ exportFileId: string; isFailed: boolean }>(pollRequest, 840000);
				const { exportFileId, isFailed } = mediaStitchExportResponseDto;
				if (isFailed) throw new Error('Failed to export gif or video');
				const downloadFilename = `${projectTitle}.${format}`;
				const presignedUrl = await getPresignedUrl(exportFileId, downloadFilename);
				commit(`${NAMESPACE}/${MutationTypes.SET_IS_EXPORTING_VIDEO}`, false, { root: true });
				$notify({
					clean: true,
				});

				if (isDownload) {
					downloadDirectFromUrl(presignedUrl, downloadFilename);
					logger.log(`Exported in : ${performance.now() - start} ms`);
					let mixpanelEventName;
					let mixpanelAttributes;
					if (format == 'gif') {
						mixpanelEventName = 'GIF Export';
						mixpanelAttributes = { 'Is Transparent Background': transparentBackground };
					} else {
						mixpanelEventName = 'Video Export';
					}
					appUtilities.$mixpanel.track(mixpanelEventName, mixpanelAttributes);
					exportResolve();
				} else {
					const outputResponse = await fetch(presignedUrl);
					const blob = await outputResponse.blob();
					exportResolve(blob);
				}
				commit(`${NAMESPACE}/${MutationTypes.UPDATE_EXPORTING_VIDEO_STATUS}`, null, { root: true });
			} catch (error) {
				commit(`${NAMESPACE}/${MutationTypes.UPDATE_EXPORTING_VIDEO_STATUS}`, null, { root: true });
				logger.error({ error }, 'Error while exporting video');
				// show notification
				$notify({
					type: NotificationType.ERROR,
					title: 'Problem occurred while exporting video. Please try again.',
				});
				commit(`${NAMESPACE}/${MutationTypes.SET_IS_EXPORTING_VIDEO}`, false, { root: true });
				appUtilities.$widgets.shortcutManager.disableShortcuts = false;
				getEngineBridge().setCurrentFrame(startFrame);
				exportReject(error);
			}
		});
	},
	async [ActionTypes.EXPORT_IMAGE](
		context,
		payload: {
			projectId: string;
			filename: string;
			width: number;
			height: number;
			isPng: boolean;
			transparentBackground: boolean;
			scaleFactor?: number;
			background?: BackgroundExportOptions;
			backgroundEffect?: EffectExportOptions;
			isDownload?: boolean;
			isPublish?: boolean;
			addWatermark?: boolean;
			sceneItemIds?: string[];
			shouldCountExport?: boolean;
		}
	) {
		const {
			projectId,
			filename,
			width,
			height,
			isPng,
			transparentBackground,
			scaleFactor = 1,
			background,
			backgroundEffect,
			isDownload = true,
			isPublish = false,
			addWatermark = false,
			sceneItemIds = [],
			shouldCountExport = true,
		} = payload;
		const contentType = isPng ? 'image/png' : 'image/jpg';
		const format = isPng ? PixRenderFormat.PNG : PixRenderFormat.JPEG;
		const filenameWithExtension = filename + (isPng ? '.png' : '.jpg');
		const highRes = scaleFactor > 1;
		let widthBeforeUpscale = highRes ? width / scaleFactor : width;
		widthBeforeUpscale = widthBeforeUpscale % 2 ? widthBeforeUpscale - 1 : widthBeforeUpscale;
		let heightBeforeUpscale = highRes ? height / scaleFactor : height;
		heightBeforeUpscale = heightBeforeUpscale % 2 ? heightBeforeUpscale - 1 : heightBeforeUpscale;
		let image = await getEngineBridge().exportImage(widthBeforeUpscale, heightBeforeUpscale, isPng, transparentBackground);
		if (shouldCountExport)
			ExportActions.countProjectExport(context, projectId).catch((error) => logger.error({ error }, 'Encounter error counting project export'));

		try {
			if (background || backgroundEffect?.url || addWatermark) {
				$toast.open({
					message: `Processing...`,
					type: NotificationType.INFO,
					duration: 3000,
				});
				const multipartPromises = [];
				let backgroundImageKey = undefined;
				let backgroundEffectKey = undefined;
				// base image
				image = image.split(',')[1];
				const imageBuffer = base64ToArrayBuffer(image);
				const imageBlob: Blob = new Blob([imageBuffer]);
				const extension = isPng ? 'png' : 'jpg';
				const baseRenderKey = `${filename.replaceAll(' ', '-')}-${uuidv4()}.${extension}`;

				multipartPromises.push(
					appUtilities.$services.multiUploadService.multipartUpload(imageBlob, MULTIPART_UPLOAD_TYPES.DEFAULT_IMAGE_EXPORT, {
						key: baseRenderKey,
						contentType: `image/${extension}`,
					})
				);

				if (background?.image) {
					backgroundImageKey = `${filename.replaceAll(' ', '-')}-${uuidv4()}`;
					const blobRes = await fetch(background.image);
					const bgBlob = await blobRes.blob();
					multipartPromises.push(
						appUtilities.$services.multiUploadService.multipartUpload(bgBlob, MULTIPART_UPLOAD_TYPES.DEFAULT_IMAGE_EXPORT, {
							key: backgroundImageKey,
						})
					);
				}
				if (backgroundEffect?.url) {
					backgroundEffectKey = `${filename.replaceAll(' ', '-')}-${uuidv4()}`;
					const blobRes = await fetch(backgroundEffect.url);
					const bgEffectBlob = await blobRes.blob();
					multipartPromises.push(
						appUtilities.$services.multiUploadService.multipartUpload(bgEffectBlob, MULTIPART_UPLOAD_TYPES.RENDERER_FOREGROUND, {
							key: backgroundEffectKey,
						})
					);
				}
				await Promise.all(multipartPromises);

				const res = await httpClient.post(`${ASSETMANAGER_PATH}/export/default/init`, {
					projectId,
					filename,
					baseRenderKey,
					uploadType: MULTIPART_UPLOAD_TYPES.DEFAULT_IMAGE_EXPORT,
					width,
					height,
					contentType,
					highRes,
					scaleFactor,
					backgroundImageKey,
					crop: background?.crop,
					effect: {
						s3Key: backgroundEffectKey,
						applyOnBackground: backgroundEffect?.type === EFFECT_IMAGE_TYPE.BACKGROUND,
					},
					color: background?.color,
					addWatermark,
					sceneItemIds,
				});
				const renderId = res.data.renderId;
				appUtilities.$mixpanel.track('M-Download: default', {
					'Render Id': renderId,
					width,
					height,
					format: 'PNG',
					'With watermark': addWatermark,
				});

				const pollRequest = async () => {
					return await httpClient.get(`${ASSETMANAGER_PATH}/export/default/status/${renderId}`);
				};
				const { presignedUrl } = await longPoll<{ presignedUrl: string }>(pollRequest, 840000);
				image = presignedUrl;
				if (isPublish) {
					ExportActions.publishSceneToCommunity(context, renderId).catch((error) =>
						logger.error({ error }, 'Encounter error publishing default render to community')
					);
				}
				$toast.open({
					type: NotificationType.SUCCESS,
					message: 'Your export is complete.',
					duration: 3000,
				});
			} else if (highRes) {
				// handle upscale of local default image export
				image = image.split(',')[1];
				const imageBuffer = base64ToArrayBuffer(image);
				const imageBlob: Blob = new Blob([imageBuffer]);
				return await ExportActions.exportImageWithTransformation(context, {
					imageBlob,
					width,
					height,
					filename: `${filename}`,
					isUpscale: true,
					isPng,
					transparentBackground,
					isDownload,
				});
			}
		} catch (error) {
			$toast.open({
				type: NotificationType.ERROR,
				text: 'Problem with export. Please try again.',
				duration: 3000,
			});
		}

		if (isDownload) {
			downloadDirectFromUrl(image, filenameWithExtension);
			let imageForMyDownload = await getEngineBridge().exportImage(widthBeforeUpscale, heightBeforeUpscale, isPng, transparentBackground);
			imageForMyDownload = imageForMyDownload.split(',')[1];
			const imageBuffer = base64ToArrayBuffer(imageForMyDownload);
			const imageBlob: Blob = new Blob([imageBuffer]);
			const extension = isPng ? 'png' : 'jpg';
			const baseRenderKey = `${filename.replaceAll(' ', '-')}-${uuidv4()}.${extension}`;
			await appUtilities.$services.multiUploadService.multipartUpload(imageBlob, MULTIPART_UPLOAD_TYPES.USER_IMAGE, {
				key: baseRenderKey,
				contentType: `image/${extension}`,
			});
			ExportActions.populateDefaultDownloadToMyDownloads(context, {
				key: baseRenderKey,
				width,
				height,
				projectId,
				fileName: filename,
				format: EXPORT_FORMAT.PNG,
			}).catch((error) => logger.error({ error }, 'Encounter error populate default download to my download'));

			mutationsWrapper.addOrUpdateServerExportItem(context, {
				renderId: uuidv4(),
				fileName: filename,
				progress: 100,
				status: PixRenderExportStatus.COMPLETED,
				format,
				exportType: PixRenderExportType.IMAGE,
				defaultDownloadUrl: imageForMyDownload,
			});
			appUtilities.$mixpanel.track('Image Export', {
				Width: width,
				Height: height,
				Format: contentType,
				'Is Transparent Background': transparentBackground,
				'With watermark': addWatermark,
			});
		}
		return image;
	},
	async [ActionTypes.FETCH_FBX_FILES]({ commit, dispatch }) {
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/export/fbx/status`);
			if (response.data?.content) {
				const fbxFiles: FBXExportFile[] = response.data.content;
				commit(`${NAMESPACE}/${MutationTypes.SET_EXPORTED_FBX_FILES}`, fbxFiles, { root: true });
				let hasProgressFile = false;
				fbxFiles.every((file) => {
					if (!file.isFailed && !file.fbxFileId) {
						hasProgressFile = true;
						return false;
					}
					return true;
				});
				if (hasProgressFile) dispatch(`${NAMESPACE}/${ActionTypes.SUBSCRIBE_TO_EXPORT_FBX_READY}`, undefined, { root: true });
			}
		} catch (error) {
			logger.error({ error }, 'Failed to fetch FBX files status');
		}
	},
	async [ActionTypes.CANCEL_EXPORT_FBX]({ commit }, exportId: string) {
		try {
			if (cancelPollExportFbxStatus) cancelPollExportFbxStatus.cancel();
			await httpClient.delete(`${ASSETMANAGER_PATH}/export/fbx/${exportId}`);
			commit(`${NAMESPACE}/${MutationTypes.DELETE_FBX_EXPORT_FILE}`, exportId, { root: true });
		} catch (error) {
			logger.error({ error }, 'Failed to fetch FBX files status');
		}
	},
	async [ActionTypes.DOWNLOAD_FBX_FILE]({ dispatch }, fbxFile: FBXExportFile) {
		const { fbxFileId, exportName, exportId } = fbxFile;
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/presigned/fbx/${fbxFileId}`, {
				params: {
					attachmentFilename: exportName,
				},
			});
			const { presignedUrl } = response.data;

			$notify({
				type: NotificationType.SUCCESS,
				title: 'Your file will download in few seconds.',
			});

			downloadDirectFromUrl(presignedUrl, exportName);
			appUtilities.$mixpanel.track('File Download', { 'File Format': 'FBX', Success: true });
			await dispatch(`${NAMESPACE}/${ActionTypes.CANCEL_EXPORT_FBX}`, exportId, { root: true });
		} catch {
			$notify({
				type: NotificationType.ERROR,
				title: 'Faild to download your file please try again.',
			});
		}
	},
	async [ActionTypes.SUBSCRIBE_TO_EXPORT_FBX_READY](context) {
		try {
			const { commit, dispatch } = context;
			const webSocketManager = WebSocketManager.lastInitSocketManager;

			const onReceiveCallback = async (payload) => {
				if (payload.exportFbx) {
					const fbxFile: FBXExportFile = payload.exportFbx;
					commit(`${NAMESPACE}/${MutationTypes.UPDATE_EXPORT_FBX_FILE}`, fbxFile, { root: true });
					$toast.open({
						message: `Your project is successfully exported.`,
						type: NotificationType.SUCCESS,
						duration: 3000,
					});
					// cancel stream
					if (fbxExportsCancelToken) fbxExportsCancelToken.cancel();
					appUtilities.$mixpanel.track('File Export', { 'File Format': 'FBX', Success: true });
				}
			};

			if (fbxExportsCancelToken) {
				fbxExportsCancelToken.cancel();
				fbxExportsCancelToken = new RSocketCancelToken();
			} else {
				fbxExportsCancelToken = new RSocketCancelToken();
			}

			const recoveryCallback = async () => {
				dispatch(`${NAMESPACE}/${ActionTypes.FETCH_FBX_FILES}`, undefined, { root: true });
			};

			await webSocketManager.subscribeRequestStream(
				WEBSOCKET_OPERATIONS.SUBSCRIBE_FBX_EXPORTS,
				null,
				onReceiveCallback,
				recoveryCallback,
				null,
				fbxExportsCancelToken
			);
		} catch (err) {
			logger.error({ err }, 'Encounter error subscribing for export fbx ready subscribe level');
		}
	},
	[ActionTypes.EXPORT_FBX](
		context,
		payload: {
			projectTitle: string;
			exportType?: EXPORT_TYPES;
			doNotExportMuteLayers: boolean;
			projectId: string;
			startFrame: number;
			endFrame: number;
		}
	) {
		const { commit, dispatch } = context;
		const { projectTitle, exportType, projectId, doNotExportMuteLayers, startFrame, endFrame } = payload;
		cancelPollExportFbxStatus = CancelToken.source();
		const cancelToken = cancelPollExportFbxStatus.token;
		const exportId = uuidv4();
		const bakeIntoSingleLayer = exportType && exportType == EXPORT_TYPES.SINGLE_ANIMATION_LAYER;
		const exportName = `${projectTitle}.fbx`;

		commit(
			`${APP_NAMESPACE}/${AppMutationTypes.SET_ENGINE_LOADING_STATUS}`,
			{
				message: 'Starting export...',
			},
			{ root: true }
		);
		ExportActions.countProjectExport(context, projectId).catch((error) => logger.error({ error }, 'Encounter error counting project export'));
		setTimeout(async () => {
			try {
				const glbData = await getEngineBridge().exportProject(projectTitle, true, bakeIntoSingleLayer, doNotExportMuteLayers, startFrame, endFrame);
				const glbBlob = glbData.glTFFiles[`${projectTitle}.glb`] as Blob;
				const key = `${uuidv4()}.glb`;
				const partSize = 6000000; // 6MB
				const contentType = 'application/octet-stream';
				await appUtilities.$services.multiUploadService.multipartUpload(
					glbBlob,
					MULTIPART_UPLOAD_TYPES.EXPORT_FBX,
					{ key, partSize, contentType },
					cancelToken
				);
				await httpClient.post(`${ASSETMANAGER_PATH}/export/fbx/init`, { key, exportId, exportName, projectId }, { cancelToken });

				commit(
					`${NAMESPACE}/${MutationTypes.SET_EXPORTED_FBX_FILES}`,
					{
						exportId,
						projectId,
						exportName,
						fbxFileId: null,
						isFailed: false,
						errorMessage: null,
					},
					{ root: true }
				);
				dispatch(`${NAMESPACE}/${ActionTypes.SUBSCRIBE_TO_EXPORT_FBX_READY}`, undefined, { root: true });
				commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_EXPORT_MODAL}`, true, { root: true });
			} catch (error) {
				appUtilities.$mixpanel.track('File Export', { 'File Format': 'FBX', Success: false });
				$notify({
					type: NotificationType.ERROR,
					title: 'Your file cannot be exported. Please try again.',
					duration: 3000,
				});
			} finally {
				cancelPollExportFbxStatus = null;
				commit(`${APP_NAMESPACE}/${AppMutationTypes.SET_ENGINE_LOADING_STATUS}`, null, { root: true });
			}
		}, 0);
	},
	[ActionTypes.EXPORT_PROJECT](
		context,
		payload: {
			projectId: string;
			filePrefix: string;
			asGLB: boolean;
			exportType?: EXPORT_TYPES;
			doNotExportMuteLayers: boolean;
			startFrame: number;
			endFrame: number;
		}
	) {
		const { commit } = context;
		commit(`${APP_NAMESPACE}/${AppMutationTypes.SET_ENGINE_LOADING_STATUS}`, { message: 'Exporting Assets...' }, { root: true });
		const { projectId, filePrefix, asGLB, exportType, doNotExportMuteLayers, startFrame, endFrame } = payload;
		const bakeIntoSingleLayer = exportType && exportType == EXPORT_TYPES.SINGLE_ANIMATION_LAYER;
		ExportActions.countProjectExport(context, projectId).catch((error) => logger.error({ error }, 'Encounter error counting project export'));
		setTimeout(() => {
			getEngineBridge()
				.exportProject(filePrefix, asGLB, bakeIntoSingleLayer, doNotExportMuteLayers, startFrame, endFrame)
				.then((file) => {
					file.downloadFiles();
					const clientRenderId = uuidv4();
					const fileFormat = asGLB ? PixRenderFormat.GLB : PixRenderFormat.GLTF;
					mutationsWrapper.addOrUpdateServerExportItem(context, {
						renderId: clientRenderId,
						fileName: filePrefix,
						progress: 100,
						status: PixRenderExportStatus.COMPLETED,
						format: fileFormat,
						exportType: PixRenderExportType.THREE_D,
						defaultDownloadUrl: file,
					});
					appUtilities.$mixpanel.track('File Export', { 'File Format': fileFormat, Success: true });
					$toast.open({
						message: `Your project is successfully exported.`,
						type: NotificationType.SUCCESS,
						duration: 3000,
					});
				})
				.catch(() => {
					const fileFormat = asGLB ? 'GLB' : 'GLTF';
					appUtilities.$mixpanel.track('File Export', { 'File Format': fileFormat, Success: false });
					$toast.open({
						type: NotificationType.ERROR,
						message: 'Your file cannot be exported. Please try again.',
						duration: 3000,
					});
				})
				.finally(() => {
					commit(`${APP_NAMESPACE}/${AppMutationTypes.SET_ENGINE_LOADING_STATUS}`, null, { root: true });
				});
		}, 50);
	},
	async [ActionTypes.CHECK_CAN_EXPORT_3D_FILE](context) {
		const response = await httpClient.put(`${ASSETMANAGER_PATH}/export/3dfile/complete`);
		if (response && response.data) {
			const { canRun } = response.data;
			return canRun;
		} else {
			return false;
		}
	},
	async [ActionTypes.SUBSCRIBE_TO_SERVER_RENDER_PROGRESS](context: ActionContext<IExportState, any>) {
		try {
			const webSocketManager = WebSocketManager.lastInitSocketManager;
			const serverRenderManager = ServerRenderManager.instance;

			const onReceiveCallback = async (payload) => {
				const progress = payload.progress;
				if (progress.progress) {
					progress.progress = parseInt(progress.progress.replace('%', '').replace(' ', ''));
				}
				logger.log('Received progress', progress.status);
				progress.status = PROGRESS_STATUS_MAPPING(progress.status);
				mutationsWrapper.addOrUpdateServerExportItem(context, progress);
			};

			const onErrorCallback = () => {
				serverRenderManager.registerWebWorker();
			};

			if (serverExportCancelToken) {
				serverExportCancelToken.cancel();
				serverExportCancelToken = new RSocketCancelToken();
			} else {
				serverExportCancelToken = new RSocketCancelToken();
			}

			const recoveryCallback = async () => {
				serverRenderManager.terminateWorker();
				logger.log('Reconnecting to render progress stream socket and fetch');
				await actionsWrapper.pollOngoingServerExportStatus(context);
				await webSocketManager.subscribeRequestStream(
					WEBSOCKET_OPERATIONS.SUBSCRIBE_RENDER_PROGRESS,
					null,
					onReceiveCallback,
					recoveryCallback,
					onErrorCallback
				);
			};
			await webSocketManager.subscribeRequestStream(
				WEBSOCKET_OPERATIONS.SUBSCRIBE_RENDER_PROGRESS,
				null,
				onReceiveCallback,
				recoveryCallback,
				onErrorCallback,
				serverExportCancelToken
			);
		} catch (err) {
			logger.error({ err }, 'Encounter error subscribing for server export');
		}
	},
	[ActionTypes.SERVER_RENDER](
		context: ActionContext<IExportState, any>,
		payload: {
			fileName: string;
			projectId?: string;
			format: PixRenderFormat;
			shadowSoftness: string;
			exportType: PixRenderExportType;
			width?: number;
			height?: number;
			samples?: number;
			isSuperSampling?: boolean;
			scaleFactor?: number;
			skipEvents?: boolean;
			fps?: number;
			repeat?: number;
			currentFrame?: number;
			frameRange?: [number, number];
			downloadImmediately?: boolean;
			background?: BackgroundExportOptions;
			backgroundEffect?: EffectExportOptions;
			isDownload?: boolean;
			isPreviewFileRender?: boolean;
			addWatermark?: boolean;
			sceneItemIds?: string[];
			readyCallback?: (presignedUrl?: string) => void;
			serverExportIdChangeCallback?: (payload: { renderId: string; isChangeCallback?: boolean; currentSelectedExport?: string }) => void;
			serverConfigReadyCallback?: () => void;
		}
	) {
		const { commit } = context;
		const {
			projectId,
			width = 1024,
			height = 1024,
			samples = 50,
			isSuperSampling,
			format,
			shadowSoftness,
			scaleFactor = 1,
			skipEvents = false,
			fps,
			repeat = 0,
			currentFrame,
			frameRange,
			exportType,
			background,
			backgroundEffect,
			addWatermark = false,
			sceneItemIds = [],
			downloadImmediately,
			isDownload = true,
			isPreviewFileRender = false,
			readyCallback,
			serverExportIdChangeCallback,
			serverConfigReadyCallback,
		} = payload;
		const fileName = !isPreviewFileRender ? `${payload.fileName} (HD)` : payload.fileName;
		const origin = isPreviewFileRender ? 'mnm' : null;
		const clientRenderId = uuidv4();
		let animationDuration = null;
		if (frameRange) {
			animationDuration = Math.ceil(frameRange[1] / (fps || 24));
		}
		mutationsWrapper.addOrUpdateServerExportItem(context, {
			renderId: clientRenderId,
			fileName,
			progress: 0,
			status: PixRenderExportStatus.PREPARING,
			format,
			exportType,
			animationDuration,
			isPreviewFileRender,
		});
		commit(`${APP_NAMESPACE}/${AppMutationTypes.SET_ENGINE_LOADING_STATUS}`, { message: 'Preparing for Cloud Render...' }, { root: true });
		ExportActions.countProjectExport(context, projectId).catch((error) => logger.error({ error }, 'Encounter error counting project export'));
		setTimeout(async () => {
			const maxDim = 300;
			let thumbnailWidth;
			let thumbnailHeight;
			if (width > height) {
				const divRatio = maxDim / width;
				thumbnailWidth = maxDim;
				thumbnailHeight = Math.round(height * divRatio);
			} else {
				const divRatio = maxDim / height;
				thumbnailWidth = Math.round(width * divRatio);
				thumbnailHeight = maxDim;
			}
			thumbnailHeight = thumbnailHeight % 2 ? thumbnailHeight - 1 : thumbnailHeight;
			thumbnailWidth = thumbnailWidth % 2 ? thumbnailWidth - 1 : thumbnailWidth;
			const engineBridge = getEngineBridge();
			const imageString = await engineBridge.exportImage(thumbnailWidth, thumbnailHeight, false, payload.background == null);
			const image = imageString.split(',')[1];
			// must wait for scene to finish render in order for scene to be ready to export GLB
			engineBridge.onAfterRenderObservable.addOnce(async () => {
				const prom = engineBridge.exportProject(fileName, true, true, true, undefined, undefined, exportType === PixRenderExportType.IMAGE);
				prom
					.finally(() => {
						commit(`${APP_NAMESPACE}/${AppMutationTypes.SET_ENGINE_LOADING_STATUS}`, null, { root: true });
					})
					.then(async (glbToUpload) => {
						if (!glbToUpload) {
							throw new Error('No GLB exported. Aborting cloud render.');
						}
						if (serverConfigReadyCallback) serverConfigReadyCallback();
						if (serverExportIdChangeCallback) serverExportIdChangeCallback({ renderId: clientRenderId });
						// Upload project glb
						const partSize = 8000000;
						const glbKey = `${uuidv4()}.glb`;
						const uploadGlbPromise = appUtilities.$services.multiUploadService.multipartUpload(
							glbToUpload.glTFFiles[`${fileName}.glb`] as Blob,
							MULTIPART_UPLOAD_TYPES.RENDERER_GLB,
							{
								key: glbKey,
								partSize,
								contentType: 'application/octet-stream',
							}
						);

						// Upload render thumbnail
						const imageBuffer = base64ToArrayBuffer(image);
						const imageBlob: Blob = new Blob([imageBuffer]);
						const imageKey = `${uuidv4()}.jpg`;
						const uploadThumbnailPromise = appUtilities.$services.multiUploadService.multipartUpload(
							imageBlob,
							MULTIPART_UPLOAD_TYPES.RENDERER_THUMBNAIL,
							{
								key: imageKey,
							}
						);
						// Upload server config
						const config = engineBridge.getServerRenderSettings();
						const body = JSON.stringify(config);
						const configBlob: Blob = new Blob([body]);
						const configKey = `${uuidv4()}.json`;
						const uploadConfigPromise = appUtilities.$services.multiUploadService.multipartUpload(
							configBlob,
							MULTIPART_UPLOAD_TYPES.RENDERER_CONFIG,
							{
								key: configKey,
								partSize,
								contentType: 'application/json',
							}
						);
						logger.log('config json', config);
						const promises = [uploadGlbPromise, uploadConfigPromise, uploadThumbnailPromise];
						// Upload background image
						let renderBackground = undefined;
						let renderBackgroundEffect = undefined;
						if (background) {
							if (background.image) {
								const blobRes = await fetch(background.image);
								const bgBlob = await blobRes.blob();
								const bgKey = uuidv4();
								const uploadBGPromise = appUtilities.$services.multiUploadService.multipartUpload(
									bgBlob,
									MULTIPART_UPLOAD_TYPES.SERVER_RENDER_BACKGROUND,
									{
										key: bgKey,
									}
								);
								promises.push(uploadBGPromise);
								renderBackground = { s3Key: bgKey, crop: background.crop };
							} else if (background.color) renderBackground = { color: background.color };
						}

						if (backgroundEffect) {
							if (backgroundEffect.url) {
								const blobRes = await fetch(backgroundEffect.url);
								const fgBlob = await blobRes.blob();
								const fgKey = uuidv4();
								const uploadFGPromise = appUtilities.$services.multiUploadService.multipartUpload(
									fgBlob,
									MULTIPART_UPLOAD_TYPES.RENDERER_FOREGROUND,
									{
										key: fgKey,
									}
								);
								promises.push(uploadFGPromise);
								renderBackgroundEffect = { s3Key: fgKey, applyOnBackground: backgroundEffect.type === EFFECT_IMAGE_TYPE.BACKGROUND };
							}
						}

						await Promise.all(promises);

						/** ----- Init Server Render ------*/
						const rendererData: {
							glbS3Key: string;
							projectId?: string;
							configS3Key: string;
							width: number;
							height: number;
							samples?: number;
							format?: string;
							shadowSoftness: string;
							fileName: string;
							scaleFactor?: number;
							skipEvents?: boolean;
							fps?: number;
							repeat?: number;
							currentFrame?: number;
							frameRange?: [number, number];
							exportType?: PixRenderExportType; // 'image' | 'gif' | 'video';
							background?: BackgroundExportOptions & { s3Key?: string };
							effect?: { s3Key?: string; applyOnBackground: boolean };
							addWatermark?: boolean;
							sceneItemIds?: string[];
							exrFileId?: string;
							isSuperSampling?: boolean;
							origin?: string;
						} = {
							glbS3Key: glbKey,
							configS3Key: configKey,
							projectId,
							width,
							height,
							samples,
							format,
							shadowSoftness,
							fileName,
							frameRange,
							currentFrame,
							scaleFactor,
							skipEvents,
							fps,
							repeat,
							exportType,
							background: renderBackground,
							effect: renderBackgroundEffect,
							addWatermark,
							sceneItemIds,
							exrFileId: config?.environment?.environmentMap?.serverFileId,
							isSuperSampling,
							origin,
						};
						/** WE GRAB SAMPLES FROM QUERY PARAM IF EXIST */
						const urlSearchParams = new URLSearchParams(window.location.search);
						const samplesQuery = urlSearchParams.get('samples');
						const widthQuery = urlSearchParams.get('width');
						const heightQuery = urlSearchParams.get('height');
						if (samplesQuery) rendererData.samples = parseInt(samplesQuery);
						if (widthQuery) rendererData.width = parseInt(widthQuery);
						if (heightQuery) rendererData.height = parseInt(heightQuery);

						const response = await httpClient.post(`${ASSETMANAGER_PATH}/export/renderer/init`, rendererData);
						const { renderId } = response.data;
						if (!isPreviewFileRender) {
							appUtilities.$mixpanel.track('M-Download: cloud-render', {
								'Render Id': renderId,
								width,
								height,
								samples,
								format,
								'Shadow Softness': shadowSoftness,
								'With watermark': addWatermark,
							});
						}

						if (!(skipEvents || downloadImmediately)) {
							appUtilities.$services.serverRenderProgressSimulator.updateRenderIdForExportItem(clientRenderId, renderId);
							mutationsWrapper.removeServerExportItem(context, clientRenderId);
							mutationsWrapper.addOrUpdateServerExportItem(context, {
								renderId,
								fileName,
								progress: 0,
								animationDuration,
								status: PixRenderExportStatus.PREPARING,
								format,
								exportType,
							});
							if (serverExportIdChangeCallback)
								serverExportIdChangeCallback({ renderId, isChangeCallback: true, currentSelectedExport: context.state.selectedServerExportItem });
						}
						await httpClient.post(`${ASSETMANAGER_PATH}/multipart/rendererThumbnail`, {
							key: imageKey,
							contentType: 'image/png',
							renderId,
							uploadType: MULTIPART_UPLOAD_TYPES.RENDERER_THUMBNAIL,
						});

						let presignedUrl = '';
						if (skipEvents || downloadImmediately) {
							// used in editor for demo purposes
							const pollRequest = async () => {
								return await httpClient.get(`${ASSETMANAGER_PATH}/export/renderer/status/${renderId}`);
							};
							const blenderRenderResponseDto = await longPoll<{ outputFileId: string; isFailed: boolean }>(pollRequest, 840000);
							const { outputFileId, isFailed } = blenderRenderResponseDto;
							if (isFailed) throw new Error('Failed to PixRender');
							const downloadFileName = `${fileName}.png`;
							presignedUrl = await getPresignedUrl(outputFileId, downloadFileName);
							if (isDownload) {
								downloadDirectFromUrl(presignedUrl, downloadFileName);
								$notify({
									type: NotificationType.SUCCESS,
									title: 'Your cloud render is complete.',
									duration: 3000,
								});
							}
						}
						if (readyCallback) readyCallback(presignedUrl);
					})
					.catch((error) => {
						mutationsWrapper.removeServerExportItem(context, clientRenderId);
						if (error.status == '400' && !error.data.canRun) {
							$notify({
								type: NotificationType.WARN,
								title: 'Maximum of 3 in progress cloud renders at a time',
								duration: 3000,
							});
						} else {
							logger.error('Encounter error server rendering', error);
							$notify({
								type: NotificationType.ERROR,
								title: 'Problem with cloud render. Please try again.',
								duration: 3000,
							});
						}
						if (readyCallback) readyCallback();
					});
			});
		}, 50);
	},
	async [ActionTypes.FETCH_EXPORT_DOWNLOADS](
		context: ActionContext<any, any>,
		payload: {
			refresh?: boolean;
			page: number;
			pageSize?: number;
			refreshOnSuccess?: boolean;
			search?: string;
			type?: string;
			animation?: string;
			status?: string;
			sortBy?: string;
		}
	) {
		try {
			const { refresh, refreshOnSuccess = false, page, pageSize = 20, search, animation, status, sortBy, type } = payload;
			const params = { pageSize, page, search, animation: animation ? animation == ProjectTypes.ANIMATED : undefined, status, sortBy, type };
			if (!refreshOnSuccess) mutationsWrapper.setIsFetchingExportDownloads(context, true);

			if (refresh) mutationsWrapper.resetExportDownloads(context);
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/export/downloads/status`, { params });
			if (response.data && response.data.content) {
				const { content, totalItems, totalPages } = response.data;
				const downloadDtos: ExportDownloadItem[] = content.map((item) => {
					return {
						renderId: item.id,
						type: item.type,
						fileName: item.fileName,
						fileId: item.fileId,
						status:
							item.type == ExportDownloadType.AI_GENERATED
								? PixRenderExportStatus.COMPLETED
								: item.fileId
								? PixRenderExportStatus.COMPLETED
								: item.isFailed
								? PixRenderExportStatus.FAILED
								: item.cancelled
								? PixRenderExportStatus.CANCELLED
								: PixRenderExportStatus.IN_PROGRESS,
						progress: item.type == ExportDownloadType.HIGH_QUALITY ? item.progress || 0 : undefined,
						prompt: item.prompt,
						format: item.format,
						exportType: item.exportType,
						thumbnailUrl: item.thumbnailUrl,
						size: item.size,
						updateDate: item.updatedAt,
						resolution: item.resolution,
						backgroundRemoved: item.backgroundRemoved,
					};
				});
				mutationsWrapper.setExportDownloads(context, downloadDtos, totalItems, totalPages, content.length ? page + 1 : page, refreshOnSuccess);
			}
		} finally {
			mutationsWrapper.setIsFetchingExportDownloads(context, false);
		}
	},
	async [ActionTypes.DOWNLOAD_EXPORT_DOWNLOAD](
		context: ActionContext<any, any>,
		payload: {
			exportType: string;
			format: string;
			fileName: string;
			outputFileId: string;
			renderId: string;
		}
	) {
		downloadServerRenderFileByRenderId(payload);
	},
	async [ActionTypes.DOWNLOAD_AI_GENERATED_FILE]({ commit }, id: string) {
		try {
			const { downloadFileName, presignedUrl } = await getAiGeneratedStatus(id);
			if (!presignedUrl) return;
			downloadDirectFromUrl(presignedUrl, downloadFileName);
		} catch {
			$notify({
				type: NotificationType.ERROR,
				title: 'Problem with download. Please try again.',
				duration: 3000,
			});
		}
	},
	async [ActionTypes.DOWNLOAD_SERVER_RENDER_FILE_BY_RENDER_ID](context, payload: { renderId: string; downloadIfNotDownloadedAlready?: boolean }) {
		let isDownloaded = false;
		try {
			const { renderId, downloadIfNotDownloadedAlready = false } = payload;
			const { outputFileId, exportType, fileName, format, downloaded } = await getBlenderRenderStatus(renderId);
			if (!outputFileId) return isDownloaded;
			mutationsWrapper.addOrUpdateServerExportItem(context, {
				renderId,
				fileName,
				format,
				exportType,
			});
			if (!downloadIfNotDownloadedAlready || !downloaded) {
				downloadServerRenderFileByRenderId({ outputFileId, exportType, fileName, format, renderId });
				isDownloaded = true;
			}
		} catch {
			$notify({
				type: NotificationType.ERROR,
				title: 'Problem with download. Please try again.',
				duration: 3000,
			});
		}
		return isDownloaded;
	},
	async [ActionTypes.DELETE_SERVER_RENDER_FILE]({ commit }, renderId: string) {
		try {
			commit(`${NAMESPACE}/${MutationTypes.REMOVE_SERVER_EXPORT_ITEM}`, renderId, { root: true });
			await httpClient.delete(`${ASSETMANAGER_PATH}/export/renderer/${renderId}`);
		} catch {}
	},
	async [ActionTypes.POLL_ONGOING_SERVER_EXPORT_STATUS](context: ActionContext<IExportState, any>) {
		try {
			for (const serverExportItem of context.state.serverExportItems) {
				const {
					data: { outputFileId, isFailed },
				} = await httpClient.get(`${ASSETMANAGER_PATH}/export/renderer/status/${serverExportItem.renderId}`);
				mutationsWrapper.addOrUpdateServerExportItem(context, {
					renderId: serverExportItem.renderId,
					progress: serverExportItem.progress,
					status: isFailed ? PixRenderExportStatus.FAILED : outputFileId ? PixRenderExportStatus.COMPLETED : PixRenderExportStatus.IN_PROGRESS,
					format: serverExportItem.format,
					exportType: serverExportItem.exportType,
				});
			}
		} catch {}
	},
	async [ActionTypes.RETRY_SERVER_RENDER_EXPORT](context, payload: { renderId: string; format: PixRenderFormat; exportType: PixRenderExportType }) {
		const { renderId, format, exportType } = payload;
		try {
			mutationsWrapper.addOrUpdateServerExportItem(context, {
				renderId,
				progress: 0,
				status: PixRenderExportStatus.PREPARING,
				format,
				exportType,
			});
			await httpClient.get(`${ASSETMANAGER_PATH}/export/renderer/retry/${renderId}`);
		} catch {}
	},
	async [ActionTypes.CANCEL_SERVER_RENDER_EXPORT]({ commit }, payload: { renderId: string; shouldRemove?: boolean }) {
		const { renderId, shouldRemove = true } = payload;
		try {
			await httpClient.put(`${ASSETMANAGER_PATH}/export/renderer/cancel/${renderId}`);
			if (shouldRemove) {
				commit(`${NAMESPACE}/${MutationTypes.REMOVE_SERVER_EXPORT_ITEM}`, renderId, { root: true });
			}
		} catch {}
	},
	async [ActionTypes.PUBLISH_SCENE_TO_COMMUNITY]({ commit }, renderId: string) {
		try {
			await httpClient.put(`${ASSETMANAGER_PATH}/export/renderer/publish/${renderId}`);
		} catch {}
	},
	async [ActionTypes.PUBLISH_AI_SCENE_TO_COMMUNITY]({ commit }, jobId: string) {
		try {
			await httpClient.put(`${ASSETMANAGER_PATH}/export/renderer/aistylist/publish/${jobId}`);
		} catch {}
	},
	async [ActionTypes.POPULATE_DEFAULT_DOWNLOAD_TO_MY_DOWNLOADS](
		{ commit },
		payload: { width: number; height: number; format: string; projectId: string; fileName: string; key: string }
	) {
		const { width, height, format, projectId, fileName, key } = payload;
		try {
			await httpClient.put(`${ASSETMANAGER_PATH}/export/default/mydownloads`, {
				key,
				type: MULTIPART_UPLOAD_TYPES.USER_IMAGE,
				projectId,
				fileName,
				width,
				height,
				format,
			});
		} catch {}
	},
	async [ActionTypes.POPULATE_IMAGE_TO_MY_DOWNLOADS](
		{ commit },
		payload: { width: number; height: number; isRemoveBackground: boolean; isUpscale: boolean; jobId?: string; imageStyleId: string }
	) {
		const { width, height, isRemoveBackground, isUpscale, jobId, imageStyleId } = payload;
		try {
			await httpClient.put(`${ASSETMANAGER_PATH}/export/renderer/aistylist/mydownloads/${imageStyleId}`, {
				scaledHeight: height,
				scaledWidth: width,
				backgroundRemoved: isRemoveBackground,
				highResolution: isUpscale,
				upScaleJobId: jobId,
			});
		} catch {}
	},
	async [ActionTypes.EXPORT_IMAGE_WITH_TRANSFORMATION](context, payload: ExportImageWithTransformation) {
		try {
			const { imageBlob, filename, width, height, isPng, transparentBackground, isUpscale, isRemoveBackground, isDownload } = payload;
			$toast.open({
				message: `Processing...`,
				type: NotificationType.INFO,
				duration: 3000,
			});
			const extension = isPng ? 'png' : 'jpg';
			const format = isPng ? PixRenderFormat.PNG : PixRenderFormat.JPEG;
			const imageKey = `${filename.replaceAll(' ', '-')}-${uuidv4()}.${extension}`;
			await appUtilities.$services.multiUploadService.multipartUpload(imageBlob, MULTIPART_UPLOAD_TYPES.IMAGE_UPSCALE, {
				key: imageKey,
				contentType: `image/${extension}`,
			});
			const jobResponse = await httpClient.post(`${ASSETMANAGER_PATH}/export/upscale/init`, {
				key: imageKey,
				type: MULTIPART_UPLOAD_TYPES.IMAGE_UPSCALE,
				contentType: `image/${extension}`,
				upscale: isUpscale
					? {
							width,
							height,
					  }
					: undefined,
				isRemoveBackground,
			});
			const { jobId } = jobResponse.data || {};
			if (jobId) {
				const fileName = `${filename}.${extension}`;
				const upscaleStatus = await httpClient.get(`${ASSETMANAGER_PATH}/export/upscale/${jobId}`, {
					params: {
						fileName,
					},
				});
				const { url } = upscaleStatus.data || {};
				if (url) {
					if (isDownload) {
						downloadDirectFromUrl(url, fileName);
						mutationsWrapper.addOrUpdateServerExportItem(context, {
							renderId: uuidv4(),
							fileName: filename,
							progress: 100,
							status: PixRenderExportStatus.COMPLETED,
							format,
							exportType: PixRenderExportType.IMAGE,
							defaultDownloadUrl: url,
						});
						$toast.open({
							type: NotificationType.SUCCESS,
							message: 'Your export is complete.',
							duration: 3000,
						});
						appUtilities.$mixpanel.track('Image Export', {
							Format: isPng ? 'image/png' : 'image/jpg',
							Width: width,
							Height: height,
							'Is Transparent Background': transparentBackground || isRemoveBackground,
							'Is Upscale': isUpscale,
							'Is Remove Background': isRemoveBackground,
						});
					}
					return url;
				}
			}
		} catch (error) {
			logger.error('Encounter error server rendering', error);
			$toast.open({
				type: NotificationType.ERROR,
				text: 'Problem with export. Please try again.',
				duration: 3000,
			});
		}
	},

	async [ActionTypes.EXPORT_AI_STYLED_IMAGE_WITH_TRANSFORMATION](context, payload: ExportAIStyledImageWithTransformation) {
		try {
			const { presignedUrl, filename, width, height, isPng, transparentBackground, isUpscale, isRemoveBackground, imageStyleId } = payload;
			$toast.open({
				message: `Processing...`,
				type: NotificationType.INFO,
				duration: 3000,
			});

			const jobResponse = await httpClient.post(`${ASSETMANAGER_PATH}/export/removebg`, {
				presignedUrl,
				type: MULTIPART_UPLOAD_TYPES.IMAGE_UPSCALE,
			});
			if (jobResponse.data) {
				const fileName = filename;
				if (jobResponse.data.jobId && jobResponse.data.presignedUrl) {
					// Populate to My Downloads
					if (imageStyleId) {
						await actionsWrapper.populateImageToMyDownloads(context, {
							width,
							height,
							isRemoveBackground,
							isUpscale,
							jobId: jobResponse.data.jobId,
							imageStyleId,
						});
					}
					downloadDirectFromPresignedUrl(jobResponse.data.presignedUrl, fileName);
					$notify({
						type: NotificationType.SUCCESS,
						title: 'Your export is complete.',
						duration: 3000,
						clean: true,
					});
					appUtilities.$mixpanel.track('AI Style Image Export', {
						Format: isPng ? 'image/png' : 'image/jpg',
						Width: width,
						Height: height,
						'Is Transparent Background': transparentBackground || isRemoveBackground,
						'Is Upscale': isUpscale,
						'Is Remove Background': isRemoveBackground,
					});
					return {};
				} else if (jobResponse.data.jobId) {
					await new Promise((resolve) => {
						const jobInterval = setInterval(async () => {
							const response = await httpClient.get(`${ASSETMANAGER_PATH}/export/removebg/${jobResponse.data.jobId}`);
							if (response?.data?.presignedUrl) {
								resolve(response.data);
								clearInterval(jobInterval);
								await downloadDirectFromPresignedUrl(response.data.presignedUrl, fileName);
								// Populate to My Downloads
								if (imageStyleId) {
									await actionsWrapper.populateImageToMyDownloads(context, {
										width,
										height,
										isRemoveBackground,
										isUpscale,
										jobId: jobResponse.data.jobId,
										imageStyleId,
									});
								}
								$notify({
									type: NotificationType.SUCCESS,
									title: 'Your export is complete.',
									duration: 3000,
									clean: true,
								});
								appUtilities.$mixpanel.track('AI Style Image Export', {
									Format: isPng ? 'image/png' : 'image/jpg',
									Width: width,
									Height: height,
									'Is Transparent Background': transparentBackground || isRemoveBackground,
									'Is Upscale': isUpscale,
									'Is Remove Background': isRemoveBackground,
								});
							}
						}, 2000);
					});
					return {};
				}
			}
			return {};
		} catch (error) {
			logger.error('Encounter error server rendering', error);
			$notify({
				type: NotificationType.ERROR,
				title: 'Problem with AI Styled export. Please try again.',
				duration: 3000,
				clean: true,
			});
			return {
				error: error,
			};
		}
	},
	async [ActionTypes.COUNT_PROJECT_EXPORT]({ commit }, projectId: string) {
		try {
			const itemIds = getEngineBridge()
				.getAllItemsInScene()
				.map((item) => item.itemId);
			await httpClient.put(`${ASSETMANAGER_PATH}/project/${projectId}/export`, { itemIds });
		} catch {}
	},

	async [ActionTypes.EXPORT_SELECTED_IMAGE](context, base64Image: string) {
		try {
			const blobRes = await fetch(base64Image);
			const bgBlob = await blobRes.blob();
			const key = `img-${uuidv4()}.png`;

			await appUtilities.$services.multiUploadService.multipartUpload(bgBlob, MULTIPART_UPLOAD_TYPES.DEFAULT_IMAGE_EXPORT, {
				key: key,
				contentType: 'image/png',
			});

			const res = await httpClient.post(`${ASSETMANAGER_PATH}/export/downloads/watermarked`, {
				key,
				uploadType: MULTIPART_UPLOAD_TYPES.DEFAULT_IMAGE_EXPORT,
				contentType: 'image/png',
			});
			if (res) {
				const { url } = res.data;
				downloadDirectFromUrl(url, key);
			}
		} catch {}
	},

	async [ActionTypes.REMOVE_IMAGE_BACKGROUND](context: ActionContext<any, any>, base64Image: string): Promise<any> {
		try {
			const image = base64Image.split(',')[1];
			const imageBuffer = base64ToArrayBuffer(image);
			const imageBlob: Blob = new Blob([imageBuffer]);
			const imageKey = `image-remove-bg-${uuidv4()}.png`;
			await appUtilities.$services.multiUploadService.multipartUpload(imageBlob, MULTIPART_UPLOAD_TYPES.IMAGE_UPSCALE, {
				key: imageKey,
				contentType: `image/png`,
			});
			const response = await httpClient.post(`${ASSETMANAGER_PATH}/export/removebg`, {
				key: imageKey,
				type: MULTIPART_UPLOAD_TYPES.IMAGE_UPSCALE,
			});
			if (response.data) {
				if (response.data.presignedUrl) {
					return response.data;
				}
				if (response.data.jobId) {
					return await new Promise((resolve) => {
						const jobInterval = setInterval(async () => {
							const jobResponse = await httpClient.get(`${ASSETMANAGER_PATH}/export/removebg/${response.data.jobId}`);
							if (jobResponse?.data?.presignedUrl) {
								resolve(jobResponse.data);
								clearInterval(jobInterval);
								return jobResponse.data;
							}
						}, 2000);
					});
				}
			}
		} catch (error) {
			$notify({ type: NotificationType.ERROR, title: 'Failed to remove background, please try again' });
		}
	},
};
